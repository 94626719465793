import { Override } from "framer"
import { useState } from "react"
import axios from "axios"

export function ActiveCampaignIntegration(): Override {
    const [email, setEmail] = useState("")

    const handleInputChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await axios.post(
                "https://your-active-campaign-url.com/api/3/contacts",
                {
                    contact: {
                        email: email,
                    },
                },
                {
                    headers: {
                        "Api-Token": "your-active-campaign-api-token",
                    },
                }
            )
            alert("Email successfully added to Active Campaign.")
        } catch (error) {
            alert("Error: " + error.message)
        }
    }

    return {
        component: (
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                    style={{ padding: "10px", fontSize: "16px", width: "100%" }}
                />
                <button
                    type="submit"
                    style={{ padding: "10px 20px", fontSize: "16px" }}
                >
                    Submit
                </button>
            </form>
        ),
    }
}
